/* eslint-disable */
export default function customSwiper3() {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        centeredSlides: true,
        centeredSlidesBounds: true,
        direction: 'horizontal',
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        speed: 1200,
        breakpoints: {
            480: {
                direction: 'vertical',
                slidesPerView: 5,
            },
        },
    });

    var galleryTop = new Swiper('.gallery-top', {
        direction: 'horizontal',
        speed: 1200,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
        },
        keyboard: {
            enabled: true,
        },
        thumbs: {
            swiper: galleryThumbs,
        },
    });
    galleryTop.on('slideChangeTransitionStart', function () {
        galleryThumbs.slideTo(galleryTop.activeIndex);
    });
    galleryThumbs.on('transitionStart', function () {
        galleryTop.slideTo(galleryThumbs.activeIndex);
    });
}
